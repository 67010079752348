import React from "react"
import { Link } from "gatsby"
import { Heading, Text } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404. Not found" />
    <Heading as="h1" my={4}>
      Not found
    </Heading>
    <Text>You just found a page that doesn&#39;t exist!</Text>
    <Text sx={{ my: 4 }}>
      Return to your previous page or just <Link to="/">go home</Link>.
    </Text>
  </Layout>
)

export default NotFoundPage
